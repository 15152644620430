import { uuid } from '@/utils'
import { upload, compressImage } from 'qiniu-js'
import * as api from '@/http/api'

export default function qiniuUpload (files, {
  errorCallback,
  completeCallback,
  compress = false,
  fileExt = 'png'
}) {

  let { name } = files
  let extension = name.split('.')[1]
  if(extension === undefined) {
    extension = fileExt
  }

  api.uploadQNToken().then(res => {
    const config = {
      useCdnDomain: true
    }
    compressImage(files, compress ? {
      noCompressIfLarger: true,
      quality: 0.9,
      maxWidth: 650,
      maxHeight:400
    } : null).then(data => {
      const uploadObservible = upload(data.dist, `${uuid().toUpperCase()}.${extension}`, res.result, {}, config)
      const observer = {
        next: (result) => {
        },
        error: () => {
          errorCallback()
        },
        complete: (res) => {
          if(res) {
            api.qiNiuGetUrl({
              param: res.key
            }).then(res => {
              completeCallback(res)    
            })
          }
        },
      }
      uploadObservible.subscribe(observer)
    }).catch(err => {})
  })
    
  
}
  